<template>
    <div > 
      <br v-if="isMobile">
      <div class="container-fluid">   
       <center> 
       <b-row class="justify-content-center  mt-2"  v-if="!loader">
          <b-col cols="11" md="8" v-if=" correcto == 0" >                

            <br><br><br>
              <h4 class="mt-5 text-center text-white" v-if="empieza == 0 && correcto == 0">
                  Haz que coincidan 3 emojis con la misma expresión y ¡suma más puntos!
                  <br><br>
                  Si te aparece la imagen del comodín ❓ ¡también puedes ganar!
              </h4> 
              <br v-if="empieza == 0 && correcto == 0"> <br v-if="empieza == 0 && correcto == 0"> 
              <h4 class="text-center " style="color:#5ab71a;" v-if="empieza == 0 && correcto == 0"><b>¿Listo?</b> Da clic <span class="text-white" style="cursor: pointer;" @click="empezarCronometro()">AQUÍ</span> para iniciar</h4>
              <br>
              <div class="text-center text-white" id="countdown2" v-if="empieza == 1" :style="!isMobile ? ' margin-top:-10px;' : ''">
                  <h2 :style="isMobile ? 'cursor:pointer; font-size:28px;'  : 'cursor:pointer; font-size:30px;'"  id="countdown2">
                      <img src="elementos/reloj.png" class="rounded" alt="Image" :style="!isMobile ? 'width:3%; margin-top:-15px;' : 'width:10%; margin-top:-5px;'"> <b>{{ countdown }}</b>
                  </h2> 
              </div>
               <br v-if="isMobile"> 
           </b-col>
           <b-col cols="12"> </b-col>
           <b-col cols="10" md="5"  v-if="empieza == 1 && !isMobile" > 
              <br>
              <b-card  style="background-color: transparent;    border:5px solid #000;  box-shadow: 5px 5px 15px #3D3D3D;" > 
                  <b-card-text class="text-center " style="margin:-25px;"> 
                    <b-row class="justify-content-center " > 
                      <b-col cols="2" md="11"> 
                          <br>             <br>
                          <div class="slotcontainer mt-2">
                              <div class="slot">
                                  <div class="symbols" id="slot1Symbols" ></div>
                              </div>
  
                              <div class="slot">
                                  <div class="symbols" id="slot2Symbols"></div>
                              </div>
  
                              <div class="slot">
                                  <div class="symbols" id="slot3Symbols"></div>
                              </div>
                              </div>
  
                              <div  class="text-center mt-4">
                                 <center> <u><b style="cursor: pointer; font-size:21px; color:#5ab71a;" @click="numeroR()">Clic aquí, y ¡haz match!</b> </u>
                                          <!-- <u class="ml-5" style="cursor: pointer; font-size:21px;" @click="respondeJuego()">Enviar respuesta</u>  -->
                                 </center>
                                 <br>
                              </div> 
                              <br>
                      </b-col>
                    </b-row>
                  </b-card-text>
              </b-card>
           </b-col>
           <b-col cols="12"  md="9" v-if="empieza == 1 && isMobile">   
              <div id="bajar"></div>
                  <b-row class="justify-content-center" v-if="correcto == 0" style="margin-top: -100px;"> 
                      <b-col cols="12"  md="7" class="mt-5 " >
                          <br ><br  v-if="vertical == 1"> <br v-if="vertical == 1"><br v-if="vertical == 1"> 
                               <br>             <br>
                          <div class="slotcontainer mt-2">
                              <div class="slot">
                                  <div class="symbols" id="slot1SymbolsM" ></div>
                              </div>
  
                              <div class="slot">
                                  <div class="symbols" id="slot2SymbolsM"></div>
                              </div>
  
                              <div class="slot">
                                  <div class="symbols" id="slot3SymbolsM"></div>
                              </div>
                              </div>
  
                              <div  class="text-center mt-4">
                                 <center> <u><b style="cursor: pointer; font-size:21px; color:#5ab71a;" @click="numeroR()">Clic aquí, y ¡haz match!</b> </u>
                                          <!-- <u class="ml-5" style="cursor: pointer; font-size:21px;" @click="respondeJuego()">Enviar respuesta</u>  -->
                                 </center>
                                 <br>
                              </div> 
                              <br>
                      </b-col> 
                  </b-row>  
           </b-col>
           <b-col cols="11" md="3" v-if="correcto != 0">    
                  <br>
              <b-card  style="background-color: #FFF; border-color: #d6d6d6;   border-radius: 50px;" class="mt-4"> 
              <b-card-text class="text-center">
                  <center>
                      <template v-if="correcto == 2"> 
                          <img  src="elementos/perder.png" class="rounded  float-center" alt="Image"  >
                          <p  :style="isMobile ? 'font-size:25px;' : 'font-size:35px; '" > 
                              <span  style="color:#8d3fe1;"><b>Gracias por participar</b></span> 
                              <br> 
                              <span :style="isMobile ? 'font-size: 20px;' : 'font-size: 30px;'">Prepárate mejor para <br>la próxima vez</span>
                          </p> 
                      </template>
                      <template v-else-if="correcto == 1"> 
                          <img  src="elementos/check.png" class="rounded  float-center" alt="Image"  style="width: 30%;"> 
                          <p  :style="isMobile ? 'font-size:30px;' : 'font-size:40px;'"  >
                          ¡Felicidades! 
                          <br><span :style="isMobile ? 'color:#5cb615; font-size: 25px;' : 'color:#5cb615; font-size: 30px;'"><b>Ganaste <br>10 Pts.</b></span> 
                          </p> 
                      </template>
                  </center>
              </b-card-text>
              </b-card>
            </b-col>
          </b-row>
          <template v-else>
              <b-row class="justify-content-center mt-5" >
                  <b-col cols="11" md="3"  style="color:#0b2739; font-size: 25px;"> 
                  <center> <b-spinner style="color: #0b2739;" v-if="loader" ></b-spinner> Procesando solicitud... </center> 
                  </b-col>
              </b-row> 
          </template>
           <br><br><br> 
            <br><br><br> 
          </center>
      </div>
    </div>
  </template>
  <script>  
  import * as moment from 'moment';
  // import $ from "jquery";
  export default {
    name: 'Emoji',
    data(){
      return{
          correcto:0,
          countdown: moment.utc(61).format('HH:mm:ss'),
          expires_in: null,
          interval: null,
          Clickinterval: false,
          loader:false,
          isMobile: this.$mobile(),
          empieza:0,
          vertical:0,
          var:[],
          arrayImg:[],
          arrayImg2:[],
          listo:0
      }
    },
    destroyed () {
        clearInterval(this.interval);
    },
    computed: {
      user() {
        return this.$store.getters.getInfoUser;
      },
    },
    methods: {
          empezarCronometro(){  
              this.empieza = 1;
              this.countdown = moment.utc(32).format('HH:mm:ss');
              this.expires_in = 1;
              this._setInterval();
              if (this.isMobile)
               {
                this.$bus.$emit("cambi_fo6", true)
               }
               
              if (window.orientation == '90'  && this.isMobile  || window.orientation == '-90'  && this.isMobile )
              {
                this.vertical = 1; 
                this.$bus.$emit("actualiza_vertical", true)
                 window.scrollTo(0, 300);   
              }else{
                this.vertical = 0; 
                 window.scrollTo(0, 500);     
              } 
              
               setTimeout(() => {
                  this.numeroR();
                  this.numeroR();
                  //  this.spin();
              }, 100);
          },
           _setInterval: function() {
              // this.mostrarPregunta = 1;
              if(this.Clickinterval==false){
                  this.interval = setInterval(() => {
                      if (this.expires_in === 0) {
                          this.empieza = 0;
                          clearInterval(this.interval);
                      } else {
                          this.expires_in += 1;
                          if(this.expires_in>0){
                              this.countdown = moment.utc(this.expires_in * 1000).subtract(1, 'seconds').format('HH:mm:ss');
                          }
                      }
                  }, 1000);
                  this.Clickinterval = true;
              }
          },
          getJuego(){
           this.loader = true;
           var url= 'juego/estatus2?email='+ this.user.email;
              this.$api.get(url).then(
                  ({data}) => {
                      console.log(data);
                      this.var = [];
                        this.reset();
                      this.loader = false;
                     if (this.isMobile)
                      {
                          this.$bus.$emit("cambi_fo2", true)
                      } 
                      this.correcto = data.correcto;
                      if (data.correcto != 0)
                      {
                          this.empieza = 0;
                          clearInterval(this.interval);    
                      }
                      if(data.correcto == 1){
                      this.$confetti.start({
                          particles: [
                              {
                              type: 'rect',
                              },
                              {
                              type: 'circle',
                              },
                          ],
                          defaultColors: [
                              '#5cb617',
                              '#ffffff',
                              '#187abe'
                          ],
                      });
                      setTimeout(() => {
                          this.$confetti.stop();
                      }, 2000);
                      }
                  }
              );
          },
          respondeJuego(){     
              this.$api.post('juego/store', {
                      mrt: this.user.mrt,
                      idpdv: this.user.id_pdv,
                      email: this.user.email,
                      seccion: 'Juego',
                      puntos: 10,
                      perfil: this.user.perfil,
                      respuesta: 'Emoji',
                      tiempo: this.countdown,
                      correcto: this.correcto
                  }).then(response => {
                          console.log(response);
                          this.loader = false;  
                          this.empieza = 0;
                          clearInterval(this.interval);
                          this.getJuego();
  
                      })
                      .catch(error => {
                          console.log(error);
                          this.loader = false;
                      });
          }, 
          spin(){ 
              // let spun = false; 
  
              // if (spun) {
              //     this.reset();
              // }
              const slots = document.querySelectorAll('.slot');
              // let completedSlots = 0;
  
              slots.forEach((slot, index)   => {
                  const symbols = slot.querySelector('.symbols');
                  const symbolHeight = symbols.querySelector('.symbol')?.clientHeight;
                  const symbolCount = symbols.childElementCount;
  
                  symbols.innerHTML = '';
  
                  symbols.appendChild(this.createSymbolElement2('❓'));
  
                  // Declare an array object for our array of images
                  // let arrayOfImages = [];
   
                  // Push image object to arrayOfImages
     
  
                  // console.log(index);
                  for (let i = 0; i < 3; i++) {
                      this.arrayImg[index].forEach(symbol => {  
                          symbols.appendChild(this.createSymbolElement(symbol));
                      }); 
                  } 
                  // const totalDistance = symbolCount * symbolHeight;
                  const randomOffset = -Math.floor(Math.random() * (symbolCount - 1) + 1) * symbolHeight;
                  symbols.style.top = `${randomOffset}px`;
  
                  // console.log(symbols);
  
                  // symbols.addEventListener('transitionend', () => {
                  // completedSlots++;
                  // if (completedSlots === slots.length) {
                  //     this.logDisplayedSymbols();
                  // }
                  // }, { once: true });
  
                  
              });
  
              setTimeout(() => {
              if (this.listo == 1)
                  {
                    this.correcto = 1;  
                    this.empieza = 0; 
                  // alert('match');
                  } 
              }, 1000);
  
              // spun = true; 
          },
          createSymbolElement2(symbol) {
             
              const div = document.createElement('div');
              div.classList.add('symbol');
              div.textContent = symbol;
              return div;
          },
          createSymbolElement(symbol) {    
              const container = document.createElement('div');
              const img = document.createElement('img');
              img.src = symbol;
              container.appendChild(img);
              return img;
          },
          reset() {
              const slots = document.querySelectorAll('.slot');
  
              slots.forEach(slot => {
                  const symbols = slot.querySelector('.symbols');
                  symbols.style.transition = 'none';
                  symbols.style.top = '0';
                  symbols.offsetHeight;
                  symbols.style.transition = '';
              });
          },
          logDisplayedSymbols() {
              const slots = document.querySelectorAll('.slot');
              const displayedSymbols = [];
  
              slots.forEach((slot, index) => {
                  // const symbols = slot.querySelector('.symbols');
                  // const symbolIndex = Math.floor(Math.abs(parseInt(40, 40)) / slot.clientHeight) % this.arrayImg[index].length;
                  // const displayedSymbol = this.arrayImg[symbolIndex];
                  displayedSymbols.push(this.arrayImg[index]);
  
                  //    console.log(this.arrayImg[symbolIndex]);
                  
              }); 
   
           
   
  
              // displayedSymbols.forEach((value) => {  
              //  this.var[value] = (this.var[value] || 0) + 1;
              // }); 
  
              console.log(displayedSymbols);
              // setTimeout(() => {
                  if (this.listo == 1)
                  {
                    this.correcto = 1;
                    this.empieza = 0;
                  // alert('match');
                  } 
              // }, 400);
          }, 
          numeroR(){
             this.arrayImg = [];
             this.listo = 0;
              var max = 9; 
             const ram =  Math.floor(Math.random() * max);  
             console.log(ram);
             switch (ram) {
               case 0:
                  this.arrayImg = [
                      ['https://convencioncomercial.com.mx/emoji/amor.png','https://convencioncomercial.com.mx/emoji/cool.png','https://convencioncomercial.com.mx/emoji/coqueto.png'],
                      ['https://convencioncomercial.com.mx/emoji/enfermo.png', 'https://convencioncomercial.com.mx/emoji/enojado.png', 'https://convencioncomercial.com.mx/emoji/feliz.png'],
                      ['https://convencioncomercial.com.mx/emoji/lengua.png','https://convencioncomercial.com.mx/emoji/lentes.png', 'https://convencioncomercial.com.mx/emoji/mareado.png']
                  ]; 
                  this.listo = 0;
              break; 
              case 1:
                  this.arrayImg = [
                      ['https://convencioncomercial.com.mx/emoji/amor.png','https://convencioncomercial.com.mx/emoji/cool.png','https://convencioncomercial.com.mx/emoji/coqueto.png'],
                      ['https://convencioncomercial.com.mx/emoji/enfermo.png', 'https://convencioncomercial.com.mx/emoji/enojado.png', 'https://convencioncomercial.com.mx/emoji/feliz.png'],
                      ['https://convencioncomercial.com.mx/emoji/lengua.png','https://convencioncomercial.com.mx/emoji/lentes.png', 'https://convencioncomercial.com.mx/emoji/mareado.png']
                  ];
                  this.listo = 0;
              break; 
              case 2:
                  this.arrayImg = [
                      ['https://convencioncomercial.com.mx/emoji/miedo.png','https://convencioncomercial.com.mx/emoji/miedo.png','https://convencioncomercial.com.mx/emoji/miedo.png'],
                      ['https://convencioncomercial.com.mx/emoji/miedo.png', 'https://convencioncomercial.com.mx/emoji/miedo.png', 'https://convencioncomercial.com.mx/emoji/miedo.png'],
                      ['https://convencioncomercial.com.mx/emoji/miedo.png','https://convencioncomercial.com.mx/emoji/miedo.png', 'https://convencioncomercial.com.mx/emoji/miedo.png']
                  ]; 
                  this.listo = 1; 
              break; 
              case 3:
                  this.arrayImg = [
                      ['https://convencioncomercial.com.mx/emoji/amor.png','https://convencioncomercial.com.mx/emoji/cool.png','https://convencioncomercial.com.mx/emoji/coqueto.png'],
                      ['https://convencioncomercial.com.mx/emoji/enfermo.png', 'https://convencioncomercial.com.mx/emoji/enojado.png', 'https://convencioncomercial.com.mx/emoji/feliz.png'],
                      ['https://convencioncomercial.com.mx/emoji/lengua.png','https://convencioncomercial.com.mx/emoji/lentes.png', 'https://convencioncomercial.com.mx/emoji/mareado.png']
                  ];
                  this.listo = 0;
              break; 
              case 4:
                   this.arrayImg.push('https://convencioncomercial.com.mx/emoji/enfermo.png','https://convencioncomercial.com.mx/emoji/enfermo.png','https://convencioncomercial.com.mx/emoji/enfermo.png',
                  'https://convencioncomercial.com.mx/emoji/enfermo.png','https://convencioncomercial.com.mx/emoji/enfermo.png','https://convencioncomercial.com.mx/emoji/enfermo.png','https://convencioncomercial.com.mx/emoji/enfermo.png'
                  ,'https://convencioncomercial.com.mx/emoji/enfermo.png','https://convencioncomercial.com.mx/emoji/enfermo.png','https://convencioncomercial.com.mx/emoji/enfermo.png');
                  this.listo = 1; 
              break; 
              case 5:
                  this.arrayImg = [
                      ['https://convencioncomercial.com.mx/emoji/amor.png','https://convencioncomercial.com.mx/emoji/cool.png','https://convencioncomercial.com.mx/emoji/coqueto.png'],
                      ['https://convencioncomercial.com.mx/emoji/enfermo.png', 'https://convencioncomercial.com.mx/emoji/enojado.png', 'https://convencioncomercial.com.mx/emoji/feliz.png'],
                      ['https://convencioncomercial.com.mx/emoji/lengua.png','https://convencioncomercial.com.mx/emoji/lentes.png', 'https://convencioncomercial.com.mx/emoji/mareado.png']
                  ]; 
                  this.listo = 0;
              break; 
              case 6:
                   this.arrayImg = [
                      ['https://convencioncomercial.com.mx/emoji/amor.png','https://convencioncomercial.com.mx/emoji/amor.png','https://convencioncomercial.com.mx/emoji/amor.png'],
                      ['https://convencioncomercial.com.mx/emoji/amor.png', 'https://convencioncomercial.com.mx/emoji/amor.png', 'https://convencioncomercial.com.mx/emoji/amor.png'],
                      ['https://convencioncomercial.com.mx/emoji/amor.png','https://convencioncomercial.com.mx/emoji/amor.png', 'https://convencioncomercial.com.mx/emoji/amor.png']
                  ]; 
                  this.listo = 1; 
              break; 
              case 7:
                  this.arrayImg = [
                      ['https://convencioncomercial.com.mx/emoji/amor.png','https://convencioncomercial.com.mx/emoji/cool.png','https://convencioncomercial.com.mx/emoji/coqueto.png'],
                      ['https://convencioncomercial.com.mx/emoji/enfermo.png', 'https://convencioncomercial.com.mx/emoji/enojado.png', 'https://convencioncomercial.com.mx/emoji/feliz.png'],
                      ['https://convencioncomercial.com.mx/emoji/lengua.png','https://convencioncomercial.com.mx/emoji/lentes.png', 'https://convencioncomercial.com.mx/emoji/mareado.png']
                  ];
                  this.listo = 0;
              break; 
              case 8:
                  this.arrayImg = [
                                      ['https://convencioncomercial.com.mx/emoji/amor.png','https://convencioncomercial.com.mx/emoji/cool.png','https://convencioncomercial.com.mx/emoji/coqueto.png'],
                                      ['https://convencioncomercial.com.mx/emoji/enfermo.png', 'https://convencioncomercial.com.mx/emoji/enojado.png', 'https://convencioncomercial.com.mx/emoji/feliz.png'],
                                      ['https://convencioncomercial.com.mx/emoji/lengua.png','https://convencioncomercial.com.mx/emoji/lentes.png', 'https://convencioncomercial.com.mx/emoji/mareado.png']
                                  ];
                  this.listo = 0;
              break; 
             }
            setTimeout(() => {
               this.spin();
                 this.spin();
             }, 200); 
          }
      }, 
  } 
  
  </script>  
   <style>
   
  body {
      background: #212121;
      margin: 0;
      padding: 0;
  }
  
  .container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 100vh;
  }
  
  .slotcontainer {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 20px;
  }
  
  .slot {
      width: 220px;
      height: 240px;
      border: 1px solid black;
      border-radius: 7.5px;
      display: inline-block;
      overflow: hidden;
      position: relative;
      background: #fafafa;
      box-shadow: 0 0 3px 2px rgba(0, 0, 0, 0.4) inset;
      margin-top: 20px;
  }
  
  .slot .symbols {
      position: absolute;
      top: 0%;
      left: 0;
      transition: top 0.5s;
      width: 80px; 
  }
  
  .slot .symbol {
      width: 100px;
      height: 50%;
      font-size: 100px;
      line-height: 100px;
      display: block;
      text-align: center;
      padding-top: 75px; 
  }
  
  button {
      margin: 0 10px;
      padding: 10px 20px;
      font-size: 16px;
  }
  
  #slot1Symbols img{
      width: 120px;
      height: 120px;
      margin-top: 55px;
      padding: 10px; 
  }

  #slot1SymbolsM img{
      width: 120px;
      height: 120px;
      margin-top: 55px;
      padding: 10px;
      margin-left: -0.4rem;
  }
  
  #slot1Symbols {
      margin: 40px;
      padding-top: 135px;
  }
  
  #slot2Symbols img{
      width: 120px;
      height: 120px;
      margin-top: 55px;
      padding: 10px;
  }

  #slot2SymbolsM img{
      width: 120px;
      height: 120px;
      margin-top: 55px;
      padding: 10px;
      margin-left: -0.4rem;
  }
  
  #slot2Symbols {
      margin: 40px;
      padding-top: 135px;
  }
  
  #slot3Symbols img{
      width: 120px;
      height: 120px;
      margin-top: 55px;
      padding: 10px; 
  }

  #slot3SymbolsM img{
      width: 120px;
      height: 120px;
      margin-top: 55px;
      padding: 10px; 
      margin-left: -0.4rem;
  }
  
  #slot3Symbols {
      margin: 40px;
      padding-top: 135px;
  }
   </style>